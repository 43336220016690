<!--  -->
<template>
  <div class="container">
    <template v-for="(item, index) in datalist">
      <div class="logist-cont" :key="index">
        <van-cell :title="item.title" :value="`共计${item.child.length}件`" v-if="item.child.length > 0"></van-cell>
        <div class="delivery-item" v-for="(it, idx) in item.child" :key="idx">
          <!-- <img src="../../assets/images/100.png" /> -->
          <div class="deli-info">
            <!-- <div class="name">快递收件(揽收)</div> -->
            <div class="express">快递单号：{{ it.expressNo }} <van-tag type="primary" color="#ff98a0" round v-clipboard:copy="it.expressNo"
              v-clipboard:success="copyBtn">复制</van-tag></div>
          </div>
          <div class="view-detail" @click="pageJump(it)">查看物流
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'OrderLogistics',
  data() {
    return {
      orderId: '',
      datalist: [
        { title: "中国产地(国内发)", child: [] },
        { title: "澳洲产地(自用取货)", child: [] },
      ]
    }
  },
  created() {
    let { orderId } = this.$route.query;
    this.orderId = orderId;
    this.getOrderInfo(orderId)
  },
  methods: {
    //获取订单详情
    getOrderInfo(orderId) {
      this.$toast.loading({ message: '加载中...', duration: 0, overlay: true })
      this.get("/OrderGoods/Order/GetOrderExpress", { orderId }, 2).then(res => {
        this.$toast.clear();
        if (res.code === 1) {
          //deliveryMethod 1国内发货 2澳洲发货
          let data = res.response;
          data.forEach(item => {
            this.datalist[item.deliveryMethod - 1].child.push(item)
          });
        }
      })
    },
    pageJump(item) {
      let path = item.deliveryMethod === 1 ? `http://wap.ivos.cn/Home/Query?tickt=${item.expressNo}` : `http://m.kuaidi100.com/result.jsp?nu=${item.expressNo}`;
      window.location.href = path;
    },
    copyBtn() {
      this.$toast('复制成功')
    },
  }
}
</script>
<style lang='scss' scoped>
.container {
  .logist-cont {
    border-bottom: 10px solid #f8f8f8;

    .van-cell {
      background-color: #fff5f6;

      /deep/ .van-cell__title {
        color: #666666;
        font-size: 12px;
      }

      /deep/ .van-cell__value {
        color: #666666;
        font-size: 12px;
      }
    }

    .delivery-item {
      padding: 10px 15px;
      display: flex;
      font-size: 12px;
      position: relative;
      align-items: center;

      img {
        width: 44px;
        height: 44px;
        margin-right: 10px;
      }

      .deli-info {
        flex: 1;
        color: #666666;

        .name {
          font-size: 14px;
          margin-bottom: 8px;
        }
      }

      .view-detail {
        width: 70px;
        color: #ff98a0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      &:after {
        content: '';
        position: absolute;
        left: 15px;
        right: 15px;
        bottom: 0;
        height: 1px;
        background-color: #f1f0f0;
      }

      &:last-child {
        &::after {
          height: 0;
        }
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
</style>